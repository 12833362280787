import { makeConstant } from "./_helpers";
const constant = makeConstant("chase/game");

export const GET_LEAGUES_LIST = constant("GET_LEAGUES_LIST");
export const GET_LEAGUES_LIST_NEW = constant("GET_LEAGUES_LIST_NEW");
export const GET_LEAGUES_SEASONS_LIST = constant("GET_LEAGUES_SEASONS_LIST");
export const GET_SEASONS_LIST = constant("GET_SEASONS_LIST");
export const GET_ROUNDS_LIST = constant("GET_ROUNDS_LIST");
export const GET_MATCHES_LIST = constant("GET_MATCHES_LIST");
export const GET_SEASON_TEAMS = constant("GET_SEASON_TEAMS");
export const GET_SEASON_PLAYERS = constant("GET_SEASON_PLAYERS");
export const GET_SEASON_PLAYERS_ROLES = constant("GET_SEASON_PLAYERS_ROLES");
export const GET_TEAM_MATCH_STATISTICS = constant("GET_TEAM_MATCH_STATISTICS");
export const GET_PLAYER_MATCH_STATISTICS = constant("GET_PLAYER_MATCH_STATISTICS");
export const GET_OVERALL_TEAM_CORE_KPIS_STATS = constant("GET_OVERALL_TEAM_CORE_KPIS_STATS");
export const GET_OVERALL_TEAM_BONUS_KPIS_STATS = constant("GET_OVERALL_TEAM_BONUS_KPIS_STATS");
export const GET_TEAM_CORE_KPIS_STATS = constant("GET_TEAM_CORE_KPIS_STATS");
export const GET_TEAM_BONUS_KPIS_STATS = constant("GET_TEAM_BONUS_KPIS_STATS");
export const GET_PLAYER_CORE_KPIS_STATS = constant("GET_PLAYER_CORE_KPIS_STATS");
export const GET_PLAYER_BONUS_KPIS_STATS = constant("GET_PLAYER_BONUS_KPIS_STATS");
export const GET_SEASON_KPIS_LIST = constant("GET_SEASON_KPIS_LIST");
export const GET_TEAM_KPIS_TRENDS = constant("GET_TEAM_KPIS_TRENDS");
export const GET_PLAYER_KPIS_TRENDS = constant("GET_PLAYER_KPIS_TRENDS");
export const GET_DIVISIONS_LIST = constant("GET_DIVISIONS_LIST");
export const GET_TEAMS_POINTS = constant("GET_TEAMS_POINTS");
export const GET_PLAYERS_POINTS = constant("GET_PLAYERS_POINTS");
export const GET_TEAM_PERFORM = constant("GET_TEAM_PERFORM");
export const GET_PLAYERS_PERFORM = constant("GET_PLAYERS_PERFORM");
export const GET_TEAM_MATCH_LEADERBOARD = constant("GET_TEAM_MATCH_LEADERBOARD");
export const GET_TEAM_BONUS_LEADERBOARD = constant("GET_TEAM_BONUS_LEADERBOARD");
export const GET_LIST_OF_REGIONS = constant("GET_LIST_OF_REGIONS");
export const GET_KPIWISE_PLAYER_LEADERBOARD = constant("GET_KPIWISE_PLAYER_LEADERBOARD");
export const GET_PLAYER_LEADERBOARD = constant("GET_PLAYER_LEADERBOARD");
export const GET_TEAM_RACE_LIVE_SCORE = constant("GET_TEAM_RACE_LIVE_SCORE");
export const GET_PLAYER_RACE_LIVE_SCORE = constant("GET_PLAYER_RACE_LIVE_SCORE");
export const GET_ROUND_TEAM_STATISTICS = constant("GET_ROUND_STATISTICS");
export const GET_ROUND_PLAYER_STATISTICS = constant("GET_ROUND_PLAYER_STATISTICS");
export const GET_MY_TEAM_LIVE_PERFORMANCE = constant("GET_MY_TEAM_LIVE_PERFORMANCE");
export const GET_MY_LIVE_PERFORMANCE = constant("GET_MY_LIVE_PERFORMANCE");
export const OPEN_LIVESCORE_SHARE = constant("OPEN_LIVESCORE_SHARE");
export const GET_STAKE_HOLDERS_LIST = constant("GET_STAKE_HOLDERS_LIST");
export const PLAYER_MATCH_DATA = constant("PLAYER_MATCH_DATA");
export const TEAM_MATCH_DATA = constant("TEAM_MATCH_DATA");
export const GET_MATCH_DETAILS = constant("GET_MATCH_DETAILS");
export const GET_LOGGEDIN_USER_ROLE = constant('GET_LOGGEDIN_USER_ROLE');
export const GET_LIVE_MATCHES_DATA = constant('GET_LIVE_MATCHES_DATA');
export const GET_OVERALL_TRENDS = constant('GET_OVERALL_TRENDS');
export const GET_TOP_TEAM = constant('GET_TOP_TEAM');
export const GET_TOP_PLAYER = constant('GET_TOP_PLAYER');
export const GET_REWARDS_LIST = constant('GET_REWARDS_LIST');
export const GET_REWARDS_POSITIONS_LIST = constant('GET_REWARDS_POSITIONS_LIST');
export const GET_UPLOAD_LOGS = constant('GET_UPLOAD_LOGS');
export const GET_FILTERS_INFO = constant('GET_FILTERS_INFO');
export const GET_SEASON_DETAILS = constant('GET_SEASON_DETAILS');
export const GET_LEAGUE_DETAILS = constant('GET_LEAGUE_DETAILS');
export const GET_USER_NOTIFICATIONS = constant('GET_USER_NOTIFICATIONS');
export const GET_USER_NOTIFICATIONS_MESSAGES = constant('GET_USER_NOTIFICATIONS_MESSAGES');
export const GET_USER_NOTIFICATIONS_MESSAGE_CATEGORIES = constant('GET_USER_NOTIFICATIONS_MESSAGE_CATEGORIES');
export const GET_REWARDS_STANDINGS = constant('GET_REWARDS_STANDINGS');
export const GET_SINGLE_SEASON_DETAIL = constant('GET_SINGLE_SEASON_DETAIL');
export const SET_SEASON_DETAILS = constant('SET_SEASON_DETAILS');
export const GET_COUNTRY_CURRENCY_LIST = constant('GET_COUNTRY_CURRENCY_LIST');
export const SET_COUNTRY_CURRENCY_LIST = constant('SET_COUNTRY_CURRENCY_LIST');
export const GET_PLAYERS_PERFORMANCE = constant('GET_PLAYERS_PERFORMANCE ');
export const GET_MATCHES_LIST_V2 = constant("GET_MATCHES_LIST_V2");
export const GET_SEASONKPI_POSITIONS = constant('GET_SEASONKPI_POSITIONS')
export const SET_SEASONKPI_POSITIONS = constant('SET_SEASONKPI_POSITIONS');
export const USER_HIERARCHIES = constant('USER_HIERARCHIES');
export const DIVISION_KPIDATA = constant('DIVISION_KPIDATA');
export const TEAM_DIVISION_KPIDATA = constant('TEAM_DIVISION_KPIDATA');
export const PERFORMANCE_GROUPS = constant('PERFORMANCE_GROUPS');
export const GETREWARDSLISTS = constant('GETREWARDSLISTS');
export const PERFORMANCE_GROUPS_TEAM = constant('PERFORMANCE_GROUPS_TEAM');
export const SEASON_LIVE_MATCHES_API = constant('SEASON_LIVE_MATCHES_API');
export const GET_MY_SEASON_TEAMS = constant('GET_MY_SEASON_TEAMS');
export const GET_PLAYER_CORE_KPI_RANKING = constant('GET_PLAYER_CORE_KPI_RANKING');
export const GET_PLAYER_BONUS_KPI_RANKING = constant('GET_PLAYER_BONUS_KPI_RANKING');
export const GET_PLAYER_ROLE_RANKING = constant('GET_PLAYER_ROLE_RANKING');
export const GET_TEAM_CORE_KPI_RANKING = constant('GET_TEAM_CORE_KPI_RANKING');
export const GET_TEAM_BONUS_KPI_RANKING = constant('GET_TEAM_BONUS_KPI_RANKING');
export const GET_SEASONPLAYER = constant('GET_SEASONPLAYER');
export const GET_WINNING_REWARD = constant('GET_WINNING_REWARD');
export const UPDATE_REWARD_REDUMPTION = constant('UPDATE_REWARD_REDUMPTION');
export const GET_ORGANISATION_STATS = constant('GET_ORGANISATION_STATS');
export const GET_SEASON_RULES = constant('GET_SEASON_RULES');
export const GET_SEASON_INDICATOR_SLABS = constant('GET_SEASON_RULES_LIST');
export const PROFILE_BREADCRUMS = constant('PROFILE_BREADCRUMS');
export const GET_EXPORT_PLAYER_KPIS_STATS = constant('GET_EXPORT_PLAYER_KPIS_STATS');
export const GET_KPIWISE_NEW_LEADERBOARD = constant('GET_KPIWISE_NEW_LEADERBOARD');
export const TAG_PEOPLE_LIST = "TAG_PEOPLE_LIST"
export const GET_SEASON_HIERARCHY_PLAYERS_ROLES = constant('GET_SEASON_HIERARCHY_PLAYERS_ROLES');
export const GET_SEASON_KPI_TRENDS = constant('GET_SEASON_KPI_TRENDS');
export const GET_SEASON_KPI_DETAILS = constant('GET_SEASON_KPI_DETAILS');
export const GET_SEASON_KPI_TEAM_TRENDS = constant('GET_SEASON_KPI_TEAM_TRENDS');
export const GET_SEASON_KPI_TRANSACTTIONS = constant('GET_SEASON_KPI_TRANSACTTIONS');
export const GET_SEASON_KPI_TRANSACTION_DATASETS = constant('GET_SEASON_KPI_TRANSACTION_DATASETS');


export const getLeaguesList = (payload) => ({
    type: GET_LEAGUES_LIST,
    payload
});
export const getLeaguesListNew = (payload) => ({
    type: GET_LEAGUES_LIST_NEW,
    payload
});
export const getLeaguesSeasonsList = (payload) => ({
    type: GET_LEAGUES_SEASONS_LIST,
    payload
})
export const getSeasonsList = (payload) => ({
    type: GET_SEASONS_LIST,
    payload
})
export const getSingleSeasonDetails = (payload) => ({
    type: GET_SINGLE_SEASON_DETAIL,
    payload
})
export const setSeasonDetails = (payload) => ({
    type: SET_SEASON_DETAILS,
    payload
})

export const getRoundsList = (payload) => ({
    type: GET_ROUNDS_LIST,
    payload
})
export const getSeasonDetails = (payload) => ({
    type: GET_SEASON_DETAILS,
    payload
})
export const getLeagueDetails = (payload) => ({
    type: GET_LEAGUE_DETAILS,
    payload
})

export const getMatchesList = (payload) => ({
    type: GET_MATCHES_LIST,
    payload
});

export const getMatchesListV2 = (payload) => ({
    type: GET_MATCHES_LIST_V2,
    payload
});
// export const getPlayersList = (payload) => ({
//     type: GET_PLAYERS_LIST,
//     payload
// });
// export const getRolesList = (payload) => ({
//     type: GET_ROLES_LIST,
//     payload
// });
// export const getTeamsList = (payload) => ({
//     type: GET_TEAMS_LIST,
//     payload
// });
export const getTeamMatchStatistics = (payload) => ({
    type: GET_TEAM_MATCH_STATISTICS,
    payload
});
export const getPlayerMatchStatistics = (payload) => ({
    type: GET_PLAYER_MATCH_STATISTICS,
    payload
});
export const getOverallTeamCoreKpisStats = (payload) => ({
    type: GET_OVERALL_TEAM_CORE_KPIS_STATS,
    payload
});
export const getOverallTeamBonusKpisStats = (payload) => ({
    type: GET_OVERALL_TEAM_BONUS_KPIS_STATS,
    payload
});
export const getTeamCoreKpisStats = (payload) => ({
    type: GET_TEAM_CORE_KPIS_STATS,
    payload
});
export const getTeamBonusKpisStats = (payload) => ({
    type: GET_TEAM_BONUS_KPIS_STATS,
    payload
});
export const getPlayerCoreKpisStats = (payload) => ({
    type: GET_PLAYER_CORE_KPIS_STATS,
    payload
});
export const getPlayerBonusKpisStats = (payload) => ({
    type: GET_PLAYER_BONUS_KPIS_STATS,
    payload
})
export const getSeasonTeams = (payload) => ({
    type: GET_SEASON_TEAMS,
    payload
})
export const getSeasonPlayers = (payload) => ({
    type: GET_SEASON_PLAYERS,
    payload
})
export const getSeasonPlayersRoles = (payload) => ({
    type: GET_SEASON_PLAYERS_ROLES,
    payload
})
export const getSeasonKpisList = (payload) => ({
    type: GET_SEASON_KPIS_LIST,
    payload
})

export const getTeamKpisTrends = (payload) => ({
    type: GET_TEAM_KPIS_TRENDS,
    payload
})

export const getPlayerKpisTrends = (payload) => ({
    type: GET_PLAYER_KPIS_TRENDS,
    payload
})

export const getDivisionsList = (payload) => ({
    type: GET_DIVISIONS_LIST,
    payload
})

export const getTeamPointsList = (payload) => ({
    type: GET_TEAMS_POINTS,
    payload
})

export const getPlayersPointsList = (payload) => ({
    type: GET_PLAYERS_POINTS,
    payload
})


export const getTeamPerformance = (payload) => ({
    type: GET_TEAM_PERFORM,
    payload
})

export const getPlayersPerformanc = (payload) => ({
    type: GET_PLAYERS_PERFORM,
    payload
})

export const getTeamMatchLeaderBoard = (payload) => ({
    type: GET_TEAM_MATCH_LEADERBOARD,
    payload
})

export const getTeamBonusLeaderBoard = (payload) => ({
    type: GET_TEAM_BONUS_LEADERBOARD,
    payload
})

export const getRegionsList = (payload) => ({
    type: GET_LIST_OF_REGIONS,
    payload
})

export const getKpiWisePlayerLeaderBoard = (payload) => ({
    type: GET_KPIWISE_PLAYER_LEADERBOARD,
    payload
});

export const getPlayerLeaderBoard = (payload) => ({
    type: GET_PLAYER_LEADERBOARD,
    payload
});

export const getTeamRaceLiveScore = (payload) => ({
    type: GET_TEAM_RACE_LIVE_SCORE,
    payload
});

export const getPlayerRaceLiveScore = (payload) => ({
    type: GET_PLAYER_RACE_LIVE_SCORE,
    payload
});

export const getRoundTeamStatistics = (payload) => ({
    type: GET_ROUND_TEAM_STATISTICS,
    payload
});

export const getRoundPlayerStatistics = (payload) => ({
    type: GET_ROUND_PLAYER_STATISTICS,
    payload
});

export const getMyTeamLivePerformance = (payload) => ({
    type: GET_MY_TEAM_LIVE_PERFORMANCE,
    payload
});

export const getMyLivePerformance = (payload) => ({
    type: GET_MY_LIVE_PERFORMANCE,
    payload
});

export const shareLiveScoreDialog = (payload) => ({
    type: OPEN_LIVESCORE_SHARE,
    payload
});

export const getStakeHoldersList = (payload) => ({
    type: GET_STAKE_HOLDERS_LIST,
    payload
});

export const getPlayerMatchData = (payload) => ({
    type: PLAYER_MATCH_DATA,
    payload
});

export const getTeamMatchData = (payload) => ({
    type: TEAM_MATCH_DATA,
    payload
});

export const getMatchDetails = (payload) => ({
    type: GET_MATCH_DETAILS,
    payload
});

//dashboard

export const getLoggedInUserRole = (payload) => ({
    type: GET_LOGGEDIN_USER_ROLE,
    payload
})

export const getLiveMatches = (payload) => ({
    type: GET_LIVE_MATCHES_DATA,
    payload
})

export const getOverallTrends = (payload) => ({
    type: GET_OVERALL_TRENDS,
    payload
})

export const getTopTeam = (payload) => ({
    type: GET_TOP_TEAM,
    payload
})
export const getTopPlayer = (payload) => ({
    type: GET_TOP_PLAYER,
    payload
});
export const getRewardsList = (payload) => ({
    type: GET_REWARDS_LIST,
    payload
});
export const getRewardsPositionsList = (payload) => ({
    type: GET_REWARDS_POSITIONS_LIST,
    payload
})

export const getUploadLogsInfo = (payload) => ({
    type: GET_UPLOAD_LOGS,
    payload
});

export const getFiltersInfo = (payload) => ({
    type: GET_FILTERS_INFO,
    payload
});
export const getUserNotification = (payload) => ({
    type: GET_USER_NOTIFICATIONS,
    payload
})

export const getUserNotificationMessages = (payload) => ({
    type: GET_USER_NOTIFICATIONS_MESSAGES,
    payload
});

export const getUserNotificationMessageCategories = (payload) => ({
    type: GET_USER_NOTIFICATIONS_MESSAGE_CATEGORIES,
    payload
})

export const getRewardStandings = (payload) => ({
    type: GET_REWARDS_STANDINGS,
    payload
})




export const getCountryCurrency = (payload) => ({
    type: GET_COUNTRY_CURRENCY_LIST,
    payload
});

export const setCountryCurrency = (payload) => ({
    type: SET_COUNTRY_CURRENCY_LIST,
    payload
})

export const getPlayersPerformance = (payload) => ({
    type: GET_PLAYERS_PERFORMANCE,
    payload
})


export const getSeasonKpiPositionList = (payload) => ({
    type: GET_SEASONKPI_POSITIONS,
    payload
});

export const setSeasonKpiPositionList = (payload) => ({
    type: SET_SEASONKPI_POSITIONS,
    payload
})
export const getUserHierarchies = (payload) => ({
    type: USER_HIERARCHIES,
    payload
})

export const getDivisionWiseKpi = (payload) => ({
    type: DIVISION_KPIDATA,
    payload
})

export const getTeamDivisionWiseKpi = (payload) => ({
    type: TEAM_DIVISION_KPIDATA,
    payload
})

export const getPlayerPerformanceGroups = (payload) => ({
    type: PERFORMANCE_GROUPS,
    payload
})

export const getTeamPerformanceGroups = (payload) => ({
    type: PERFORMANCE_GROUPS_TEAM,
    payload
})

export const getRewardsLists = (payload) => ({
    type: GETREWARDSLISTS,
    payload
});

export const getSeasonLiveMatchType = (payload) => ({
    type: SEASON_LIVE_MATCHES_API,
    payload
})

export const getMySeasonTeams = (payload) => ({
    type: GET_MY_SEASON_TEAMS,
    payload
})

//kpiRanking
export const getPlayerCoreKpiRanking = (payload) => ({
    type: GET_PLAYER_CORE_KPI_RANKING,
    payload
})

export const getPlayerBonusKpiRanking = (payload) => ({
    type: GET_PLAYER_BONUS_KPI_RANKING,
    payload
})

export const getPlayerRoleRanking = (payload) => ({
    type: GET_PLAYER_ROLE_RANKING,
    payload
})

export const getTeamCoreKpiRanking = (payload) => ({
    type: GET_TEAM_CORE_KPI_RANKING,
    payload
})

export const getTeamBonusKpiRanking = (payload) => ({
    type: GET_TEAM_BONUS_KPI_RANKING,
    payload
})

export const getSeasonPlayerRank = (payload) => ({
    type: GET_SEASONPLAYER,
    payload
})

export const getRewardsWinnings = (payload) => ({
    type: GET_WINNING_REWARD,
    payload
})

export const updateRewardRedumption = (payload) => ({
    type: UPDATE_REWARD_REDUMPTION,
    payload
})

export const getOrganisationStats = (payload) => ({
    type: GET_ORGANISATION_STATS,
    payload
})

//seasonRules
export const getSeasonIndicatorSlabs = (payload) => ({
    type: GET_SEASON_INDICATOR_SLABS,
    payload
})


export const getSeasonRules = (payload) => ({
    type: GET_SEASON_RULES,
    payload
})

//export stats
export const getExportPlayerKpisStats = (payload) => ({
    type: GET_EXPORT_PLAYER_KPIS_STATS,
    payload
});

export const getBreadcrumsData = (payload) => ({
    type: PROFILE_BREADCRUMS,
    payload
})


export const getKpiWiseNewLeaderBoard = (payload) => ({
    type: GET_KPIWISE_NEW_LEADERBOARD,
    payload
});
export const setTagPeopleList = (payload) => ({
    type: TAG_PEOPLE_LIST,
    payload
})

export const getSeasonHierarchiePlayersRoles = (payload) => ({
    type: GET_SEASON_HIERARCHY_PLAYERS_ROLES,
    payload
})

export const getSeasonKpiTrends = (payload) => ({
    type: GET_SEASON_KPI_TRENDS,
    payload
})

export const getSeasonKpiDetails = (payload) => ({
    type: GET_SEASON_KPI_DETAILS,
    payload
})

export const getSeasonKpiTeamTrends = (payload) => ({
    type: GET_SEASON_KPI_TEAM_TRENDS,
    payload
})

export const getSeasonKpiTransactions = (payload) => ({
    type: GET_SEASON_KPI_TRANSACTTIONS,
    payload
})

export const getSeasonKpiTransactionDatasets = (payload) => ({
    type: GET_SEASON_KPI_TRANSACTION_DATASETS,
    payload
})

const initialState = {
    liveScoresShare: {
        open: false,
        matchDetails: null,
        fixture: 'ROUND',
        details: {},
        notify: 'TEAM',
        filtersData: {}
    },
    playerMatchData: {},
    seasonDetails: {},
    seasonKpiPostions: {},
    countryLists: [],
    teamMatchData: {
        data: [],
        columns: [],
        headers: []
    },
    filtersData: {

    },
    breadcrumsData: [],
    tagPeopleList: []
};
//Reducers
export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LIVESCORE_SHARE: {
            return {
                ...state,
                liveScoresShare: { ...action.payload }
            }
        }
        case TEAM_MATCH_DATA: {
            return {
                ...state,
                teamMatchData: {
                    ...action.payload
                }
            }
        }
        case PLAYER_MATCH_DATA: {
            return {
                ...state,
                playerMatchData: { ...action.payload }
            }
        }
        case GET_FILTERS_INFO: {
            return {
                ...state,
                filtersData: { ...action.payload }
            }
        }
        case SET_SEASON_DETAILS:
            return {
                ...state,
                seasonDetails: {
                    ...action.payload
                }
            }
        case SET_COUNTRY_CURRENCY_LIST:
            return {
                ...state,
                countryLists: [
                    ...action.payload
                ]
            }
        case SET_SEASONKPI_POSITIONS:
            return {
                ...state,
                seasonKpiPostions: [
                    ...action.payload
                ]
            }
        case PROFILE_BREADCRUMS:
            return {
                ...state,
                breadcrumsData: [
                    ...action.payload
                ]
            }
        case TAG_PEOPLE_LIST:
            return {
                ...state,
                tagPeopleList: [
                    ...action.payload
                ]
            }
        default: return state
    }
}

