import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Equalizer, GolfCourse } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setSidebarIndex } from '../../ducks/sidebar';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import { ReactComponent as ReactChatLogo } from "../../assets/icons/chat-1.svg";
import { ReactComponent as ReactSurveyLogo } from "../../assets/icons/survey-1.svg";
import { ReactComponent as ReactMeetingLogo } from "../../assets/icons/meet-1.svg";
// import { ReactComponent as ReactGameIcon } from "../../assets/icons/game-1.svg";
import { ReactComponent as ReactBoardIcon } from "../../assets/icons/board-1.svg";

import { ReactComponent as ReactSupportIconFilled } from "../../assets/icons/support-2.svg";
import { ReactComponent as ReactArenaLogoFilled } from "../../assets/icons/arena-2.svg";
import { ReactComponent as ReactChatLogoFilled } from "../../assets/icons/chat-2.svg";
import { ReactComponent as ReactSurveyLogoFilled } from "../../assets/icons/survey-2.svg";
import { ReactComponent as ReactMeetingLogoFilled } from "../../assets/icons/meet-2.svg";
import { ReactComponent as ReactGameIconFilled } from "../../assets/icons/game-2.svg";
import { ReactComponent as ReactBoardIconFilled } from "../../assets/icons/board-2.svg";

// import { ReactComponent as ReactEmployee } from "../../assets/icons/employee-1.svg";
// import { ReactComponent as ReactTeam } from "../../assets/icons/team-1.svg";
// import { ReactComponent as ReactDataIcon } from "../../assets/icons/data-1.svg";

import { ReactComponent as ReactEmployeeFilled } from "../../assets/icons/employee-2.svg";
import { ReactComponent as ReactTeamFilled } from "../../assets/icons/team-2.svg";
import { ReactComponent as ReactDataIconFilled } from "../../assets/icons/data-2.svg";
import { ReactComponent as ReactBuzz } from "../../assets/icons/buzz.svg";
import { ReactComponent as ReactBuzzFilled } from "../../assets/icons/buzzfilled.svg";
import { ReactComponent as ReactIncentives } from "../../assets/icons/incentives.svg";
import { ReactComponent as ReactIncentive } from "../../assets/icons/incentive.svg";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75px',
    maxWidth: '75px',
    zIndex: 100,
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // display: 'flex',
    // height: '91vh',
    // marginTop: '64px'
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#f0f0f0',
    // position: 'fixed',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRight: '1px solid #ddd'
    }
  },
  tabRoot: {
    minWidth: '75px',
    width: '75px',
    height: '70px',
    textTransform: 'capitalize',
    fontSize: '0.85rem',
    padding: '15px 0px',
    '&:focus': {
      outline: 'none',
      // backgroundColor:theme.palette.primary.light
    }
  },
  tabIndicator: {
    width: '4px',
    right: 'auto',
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // backgroundColor: 'orange'
  },
  tabWrapper: {
    fontSize: '11px',
    fontWeight: 'bold',
    '& > *:first-child': {
      fontSize: '20px',
      marginBottom: '3px !important'
    }
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.light
  },
  iconStyle: {
    '& svg': {
      width: 25,
      height: 25
    }
  }
}));

// Available Modules in this app, "feature" value should be matched to orgDetails modules
export const AvailableModules = [
  { feature: "performance", icon: <ReactArenaLogo />, filledIcon: <ReactArenaLogoFilled />, path: 'leagues' },
  { feature: "wall", icon: <ReactArenaLogo />, filledIcon: <ReactArenaLogoFilled />, path: 'wall' },
  { feature: "buzz", icon: <ReactBuzz />, filledIcon: <ReactBuzzFilled />, path: 'buzz' },
  { feature: "chat", icon: <ReactChatLogo />, filledIcon: <ReactChatLogoFilled />, path: 'chats' },
  { feature: "survey", icon: <ReactSurveyLogo />, filledIcon: <ReactSurveyLogoFilled />, path: 'survey' },
  { feature: "meet", icon: <ReactMeetingLogo />, filledIcon: <ReactMeetingLogoFilled />, path: 'meet' },
  { feature: "board", icon: <ReactBoardIcon />, filledIcon: <ReactBoardIconFilled />, path: 'product-management' },
  // { feature: "live scores", icon: <GolfCourse style={{ color: '#00000061' }} />, filledIcon: <GolfCourse />, path: 'arena/live-scores' },
  // { feature: "performance stats", icon: <Equalizer style={{ color: '#00000061' }} />, filledIcon: <Equalizer />, path: 'arena/performance-stats' },
  // { feature: "points table", icon: <Equalizer style={{ color: '#00000061' }} />, filledIcon: <Equalizer />, path: 'arena/points-table' },
  // { feature: "leaderboards", icon: <Equalizer style={{ color: '#00000061' }} />, filledIcon: <Equalizer />, path: 'arena/leaderboard' }
  { feature: "incentives", icon: <ReactIncentive />, filledIcon: <ReactIncentives />, path: 'incentives' }
];

export const AvailablePwcModules = [
  { feature: "dashboard", icon: <ReactArenaLogo />, filledIcon: <ReactArenaLogoFilled />, path: 'dashboard' },
  { feature: "performance", icon: <ReactArenaLogo />, filledIcon: <ReactArenaLogoFilled />, path: 'performance' },
  { feature: "wall", icon: <ReactArenaLogo />, filledIcon: <ReactArenaLogoFilled />, path: 'wall' },

  { feature: "action", icon: <ReactChatLogo />, filledIcon: <ReactChatLogoFilled />, path: 'product-management' },
  // { feature: "review", icon: <ReactChatLogo />, filledIcon: <ReactChatLogoFilled />, path: 'review' },
  { feature: "buzz", icon: <ReactChatLogo />, filledIcon: <ReactChatLogoFilled />, path: 'chats' },
  { feature: "survey", icon: <ReactSurveyLogo />, filledIcon: <ReactSurveyLogoFilled />, path: 'survey' },
  { feature: "incentive", icon: <ReactSurveyLogo />, filledIcon: <ReactSurveyLogoFilled />, path: 'incentive' },
  { feature: "rewards", icon: <ReactSurveyLogo />, filledIcon: <ReactSurveyLogoFilled />, path: 'rewards' },



];

export const AvailableAdminModules = [
  // Employees & Teams are Default
  // { feature: "employees", icon: <PersonIcon style={{ fill: '#535353' }} />, path: 'employees' },
  // { feature: "teams", icon: null, path: 'teams' },
  { feature: "survey", icon: <ReactSurveyLogoFilled />, path: 'surveys' },
  { feature: "board", icon: <ReactBoardIconFilled />, path: 'pms' },
  { feature: "arena", icon: <ReactGameIconFilled />, path: 'arena' },
  { feature: "support", icon: <ReactSupportIconFilled />, path: 'wall' },
]

const DefaultAdminModules = [
  // Data room, Employees & Teams are Default
  { feature: "employees", icon: <ReactEmployeeFilled />, path: 'employees' },
  { feature: "teams", icon: <ReactTeamFilled />, path: 'teams' },
  { feature: "data master", icon: <ReactDataIconFilled style={{ height: 19 }} />, path: 'data_room' },
]

export const finalAdminAccessModules = (orgDetails) => {
  return orgDetails && orgDetails.modules && orgDetails.modules.reduce((result, item) => {
    const module = AvailableAdminModules.find(obj => (item === 'wall') ? (obj.feature === 'support') : (obj.feature === item));
    if (module) return [...result, module]
    else return result;
  }, [...DefaultAdminModules]);
}

export const finalAccessModules = (orgDetails) => {
  return orgDetails && orgDetails.modules && orgDetails.modules.reduce((result, item) => {
    const module = AvailableModules.filter(obj => (item === 'arena') ? (obj.feature === 'live scores' || obj.feature === 'performance stats' || obj.feature === 'leaderboards' || obj.feature === 'points table') || (obj.feature === 'performance') : (obj.feature === item));
    if (module) return [...result, ...module]
    else return result;
  }, []);
}
export const finalPwcAccessModules = (orgDetails) => {
  return orgDetails && orgDetails.modules && orgDetails.modules.reduce((result, item) => {
    const module = AvailablePwcModules.filter(obj => (item === 'arena') ? (obj.feature === 'live scores' || obj.feature === 'performance stats' || obj.feature === 'leaderboards' || obj.feature === 'points table') || (obj.feature === 'contests') : (obj.feature === item));
    if (module) return [...result, ...module]
    else return result;
  }, []);
}
const SideNavBar = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const initialIndex = (locationUrl) => {
    let result = props.orgDetails.modules.findIndex((item) => locationUrl.startsWith(`/${item.path}`))
    return result;
  }

  useEffect(() => {
    let locationUrl = props.location.pathname;
    if (initialIndex(locationUrl) != -1) setValue(initialIndex(locationUrl));
  }, [props.orgDetails]);

  useEffect(() => {
    let index = props.sidebar.index;
    if (index !== null) {
      props.history.push(`/${props.orgDetails.modules[index].path}`);
      setValue(index);
    }
  }, [props.sidebar]);

  useEffect(() => {
    let locationUrl = props.location.pathname;
    if (initialIndex(locationUrl) != -1) setValue(initialIndex(locationUrl));
  }, [props.location.pathname])

  const handleChange = (event, newValue) => {
    props.history.push(`/${props.orgDetails.modules[newValue].path}`);
    setValue(newValue);
    if (props.sidebar.index === newValue) {
      props.setSidebarIndex(null);
    }
    else
      props.setSidebarIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        indicatorColor="primary"

        className={classes.tabs}
        classes={{ indicator: classes.tabIndicator }}
      >
        {
          props.orgDetails.modules.map((item, i) =>
            <Tab key={i} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, selected: classes.selectedTab }} icon={item.icon} className={classes.iconStyle} label={item.feature} {...a11yProps(0)} />
          )
        }
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  sidebar: state.sidebar,
  orgDetails: state.common.orgDetails
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarIndex: (options) => dispatch(setSidebarIndex(options))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SideNavBar);